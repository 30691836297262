import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Roulette`}</h1>
    <p>{`Roulette är ett kasinospel som kommer ifrån Frankrike och betyder även litet hjul på Franska. I spelet kan spelarna välja att satsa på antingen ett nummer eller en nummerserie, färgerna rött eller svart, eller om numret är udda eller jämnt.`}</p>
    <p>{`För att bestämma det vinnande nummet för en runda så spinner croupier roulette hjulet och sedan spelkullan i en motsatt riktning. När kullan sedan har gjort minst två varv i sin banna runt hjulet och landar på ett nummer med en färg så bestäms det vinnande nummet och färg. Inom den europeiska varianten så finns det 37 nummer som kullan kan på landa på och inom den amerikanska versionen så finns det där istället 38 nummer då den har två dubbel nollor och detta gör den till en något mindre fördelaktig för spelaren.`}</p>
    <h1>{`Historia`}</h1>
    <p>{`Roulette har spelats endast sedan 1796 i Paris. I en tidig beskrivning av spelet i sin nuvarande from finns i fransk roman vid namn ”La Roulette, ou le Jour av Jaques Lablee, i den här boken så beskrivs roulettehjulet på Casinot Palais Royal i Paris 1796. Spelet var beskrivet exakt som det var utformat idag med en skillnad att den hade två nollor istället för en som är det som är det vanliga för europeisk roulette. Boken publicerades år 1801. Det finns en ännu tidigare referens från 1758 till roulette då det publicerades en bestämmelse i nya Frankrike att alla spel på tärningar, roulette och de flesta andra hasard spel skulle förbjudas.`}</p>
    <p>{`Till en början när roulette kom så hade de båda dubbel nollorna inte den gröna färgen utan var svarta och röda, då det kunde bli missförstånd så började man göra om nollan till grön istället senare på 1800 talet.`}</p>
    <p>{`Det var först 1843 på Spa Casino i den tyska staden Hamburg som man började använda en nolla på hjulet istället för två. Detta var för att tävla emot andra casino som erbjuder ett traditionellt hjul med dubbel nollor.`}</p>
    <p>{`På 1900 talet så började roulette sprida sig över hela Europa och USA då det var det mest populära casinospelet. När den tyska regeringen avskaffade spel på 1860-talet så flyttade grundarens familj Blanca över all sin casino verksamhet i Monte Carlo, där skapade dom ett spel mekka för eliten i Europa och detta är något som man kan säga även lever kvar idag då Monte Carlo är känt som en plats för de rikaste. Det var här som roulette hjulet med en nolla blev extremt populärt och börjades exporteras över hela världen med undantag av USA som hade kvar roulette hjul med dubbel nolla.`}</p>
    <p>{`Det finns en myt som säger att skaparen av roulette François Blanc hade förhandlat med djävulen för att få hemligheterna om roulette, denna myt bygger på det faktum att alla nummer på ett roulette hjul blir totalt 666 vilket är djävulens nummer.`}</p>
    <h1>{`Roulette Online`}</h1>
    <p>{`Idag så går det även bra att spela roulette online på nätet då alla internet casinos som finns ute just nu har i regel alltid roulette då det är ett av de absolut populäraste spelet ute bland alla spelare. Alla internet casinos som vi rekomondera går det utmärkt att spela roulette på och det finns även så att man kan spela live roulette. När man spelar Live Roulette så tittar man på ett roulette hjul i realtid och man kan då placera sina spelen precis som på ett vanligt casino.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      